import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import Meta from "../components/meta";
import Layout from "../components/layout";
import Slider from "react-slick";

// Slider setting
const sliderSettings = {
  dots: false,
  fade: true,
  arrows: false,
  infinite: false,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnFocus: false,
  pauseOnHover: false,
  autoplay: true,
  autoplaySpeed: 8000,
};

// markup
const IndexPage = () => {
  return (
    <Layout>
      {/* サイトタイトル */}
      <Meta title="" />

      <section className="mv">
        <div className="slide">
          <Slider {...sliderSettings}>
            <StaticImage src="../images/slide/topimg_bw.jpg" alt="スライド" />
            <StaticImage src="../images/slide/slide_2@2x.png" alt="スライド" />
            <StaticImage src="../images/slide/slide_3@2x.png" alt="スライド" />
            <StaticImage src="../images/slide/slide_4@2x.png" alt="スライド" />
            <StaticImage src="../images/slide/slide_5@2x.png" alt="スライド" />
          </Slider>
        </div>

        <div className="electric-generating mt-md-5">
          <Container>
            <Row>
              <Col md={6} className="mb-md-0 mb-4">
                <div className="rounded bg-white overflow-hidden electric-generating--primary">
                  <p className="font--large text-center bg-primary text-white font-weight-bold m-0 py-2">本日の総発電量</p>
                  <div className="py-3 text-center">
                    <iframe title="energy" src="https://www.heian-corp.jp/solarpower/day.php" width="100%" height="200" style={{ border: "none" }}></iframe>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="rounded bg-white overflow-hidden electric-generating--success">
                  <p className="font--large text-center bg-success text-white font-weight-bold m-0 py-2">稼働中の発電所全体の年間発電量</p>
                  <div className="py-3 text-center">
                    <iframe title="energy" src="https://www.heian-corp.jp/solarpower/year.php" width="100%" height="200" style={{ border: "none" }}></iframe>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <div className="home-links">
        <a href="https://www.heian-corp.jp/sun/" className="link" target="_blank" rel="noreferrer">
          <StaticImage quality={100} layout="fullWidth" alt="リンク" src="../images/menus/propertyinfo.jpg" />
        </a>
        <Link to="/maintenance/" className="link">
          <StaticImage quality={100} layout="fullWidth" alt="リンク" src="../images/menus/maintenance.jpg" />
        </Link>
        <Link to="/tochi/" className="link">
          <StaticImage quality={100} layout="fullWidth" alt="リンク" src="../images/menus/landrecruitment.jpg" />
        </Link>
        <a href="https://www.heian-corp.jp/solar_ranking/" className="link" target="_blank" rel="noreferrer">
          <StaticImage quality={100} layout="fullWidth" alt="リンク" src="../images/menus/ranking.jpg" />
        </a>
        <Link to="/works/" className="link">
          <StaticImage quality={100} layout="fullWidth" alt="リンク" src="../images/menus/construction.jpg" />
        </Link>
        <a href="https://www.heian-corp.jp/?page_id=125" className="link" target="_blank" rel="noreferrer">
          <StaticImage quality={100} layout="fullWidth" alt="リンク" src="../images/menus/company.jpg" />
        </a>
        <a href="https://www.heian-corp.jp/?page_id=131" className="link" target="_blank" rel="noreferrer">
          <StaticImage quality={100} layout="fullWidth" alt="リンク" src="../images/menus/recruitment.jpg" />
        </a>
        <a href="https://www.heian-corp.jp/contact/solar2/" className="link" target="_blank" rel="noreferrer">
          <StaticImage quality={100} layout="fullWidth" alt="リンク" src="../images/menus/contact.jpg" />
        </a>
        <a href="https://www.heian-corp.jp/?p=207" className="link" target="_blank" rel="noreferrer">
          <StaticImage quality={100} layout="fullWidth" alt="リンク" src="../images/menus/heiantheater.jpg" />
        </a>
      </div>

      <section id="news" className="sec news position-relative">
        <Container>
          <Row>
            <Col md={9}>
              <h2 className="ttl--h2">産業用・分譲太陽光発電新着ニュース・更新情報</h2>
              <div>
                <iframe
                  title="news"
                  width="100%"
                  height="300"
                  src="https://www.heian-corp.jp/solarpower/event.php"
                  style={{
                    border: "none",
                  }}
                ></iframe>
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-2">
                <Link to="/eravel/">
                  <StaticImage layout="fullWidth" alt="リンク" src="../images/bnr_side_1@2x.png" />
                </Link>
              </div>
              <div className="mb-2">
                <Link to="/online/">
                  <StaticImage layout="fullWidth" alt="リンク" src="../images/bnr_side_2@2x.png" />
                </Link>
              </div>
              <div className="mb-2">
                <a href="https://www.heian-corp.jp/?page_id=129" target="_blank" rel="noreferrer">
                  <StaticImage layout="fullWidth" alt="リンク" src="../images/bnr_side_3@2x.png" />
                </a>
              </div>
              <div className="mb-2">
                <a href="https://www.heiancorp-sacasso.jp/" target="_blank" rel="noreferrer">
                  <StaticImage layout="fullWidth" alt="リンク" src="../images/bnr_side_4@2x.png" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="about" className="about sec bg-light">
        <Container>
          <Row>
            <Col md={9} className="mb-md-0 mb-4">
              <h2 className="ttl--h2-2 text-primary kosugi text-center">
                平安コーポレーションの
                <br className="d-md-none" />
                太陽光発電事業部
              </h2>
              <p className="m-0" style={{ lineHeight: `2` }}>
                平安コーポレーションの太陽光発電事業部では、主に三重県(名張や伊賀など)を中心に全国で太陽光発電の分譲地の販売や施工・設計など太陽光発電に関することは幅広く対応しております。
                <br />
                また当ホームページ上に本日の総発電量の記載や売電額などを記載しておりますので、安心してご依頼頂けるかと思います。
                <br />
                大きな土地から小さな土地までどんな条件下でも弊社の太陽光発電事業部では、ご相談を承っておりますので、何なりとお申し付けくださいませ。
                <br />
                また最近ではオンラインによる商談も可能となっておりますので、全国のお客様とお話することができます。この機会にぜひお気軽にお問い合わせください。
              </p>
            </Col>
            <Col md={3}>
              <h2 className="ttl--h2">新着情報</h2>
              <div>
                <iframe title="news" width="100%" height="300" style={{ border: "none" }} src="https://www.heian-corp.jp/solarpower/news.php"></iframe>
              </div>
              <p className="mb-0 mt-4 text-right">
                <a href="https://www.heian-corp.jp/?cat=7" target="_blank" rel="noreferrer">
                  お知らせ一覧へ
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default IndexPage;
